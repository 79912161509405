import _ from 'lodash';

export function lukepomfrey() {
  if (!window.console || !_.isFunction(window.console.log)) {
    return;
  }
  window.console.log('');
  window.console.log(
    '%c                    .+hmMMMMNdho/.                 ',
    'color: #c9c9c9;'
  );
  window.console.log(
    '%c                   sMMMMMMMMMMMMMMNy/`             ',
    'color: #c6c6c6;'
  );
  window.console.log(
    '%c                  sMMMMMMMMMMMMMMMMMMMh:           ',
    'color: #c3c3c3;'
  );
  window.console.log(
    '%c                  MMMMMMMMMMMMMMMMMMMMMMNo`        ',
    'color: #c0c0c0;'
  );
  window.console.log(
    '%c                  MMMMMMMMMMMMMMMMMMMMMMMMNo`      ',
    'color: #bdbdbd;'
  );
  window.console.log(
    '%c                  hMMMMMMMMMMMMMMMMMMMMMMMMMN/     ',
    'color: #bababa;'
  );
  window.console.log(
    '%c     -+syyyys+/.  -MMMMMMMMMMMMMMMMMMMMMMMMMMMh`   ',
    'color: #b8b8b8;'
  );
  window.console.log(
    '%c  `sNMMMMMMMMMMMMdsdMdo/---:/sdMMMMMMMMMMMMMMMMm.  ',
    'color: #b5b5b5;'
  );
  window.console.log(
    '%c .mMMMMMMMMMMMMMMMMd-          `+mMMMMMMMMMMMMMMN. ',
    'color: #b2b2b2;'
  );
  window.console.log(
    '%c dMMMMMMMMMMMMMMMMh               +MMMMMMMMMMMMMMm`',
    'color: #aeaeae;'
  );
  window.console.log(
    '%c MMMMMMMMMMMMMMMMM:                :MMMMMMMMMMMMMMs',
    'color: #ababab;'
  );
  window.console.log(
    '%c NMMMMMMMMMMMMMMMM:       ss        sMMMMMMMMMMMMMN',
    'color: #a9a9a9;'
  );
  window.console.log(
    '%c yMMMMMMMMMMMMMMMMh        `        :MMMMMMMMMMMMMM',
    'color: #a6a6a6;'
  );
  window.console.log(
    '%c .MMMMMMMMMMMMMMMMMs                oMMMMMMMMMMMMMd',
    'color: #a3a3a3;'
  );
  window.console.log(
    '%c  /MMMMMMMMMMMMMMMMMd:             :MMMMMMMMMMMMMm.',
    'color: #a0a0a0;'
  );
  window.console.log(
    '%c   +MMMMMMMMMMMMMMMMMMd+.        :hMhNMMMMMMMMMdo` ',
    'color: #9d9d9d;'
  );
  window.console.log(
    '%c    /MMMMMMMMMMMMMMMMMMMMNdhyyhdMMMMy `-:////-`    ',
    'color: #9a9a9a;'
  );
  window.console.log(
    '%c     -mMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM+             ',
    'color: #989898;'
  );
  window.console.log(
    '%c       oMMMMMMMMMMMMMMMMMMMMMMMMMMMMMN`            ',
    'color: #959595;'
  );
  window.console.log(
    '%c        .yMMMMMMMMMMMMMMMMMMMMMMMMMMMM+            ',
    'color: #929292;'
  );
  window.console.log(
    '%c          -yMMMMMMMMMMMMMMMMMMMMMMMMMMs            ',
    'color: #8e8e8e;'
  );
  window.console.log(
    '%c            .sNMMMMMMMMMMMMMMMMMMMMMMM+            ',
    'color: #8b8b8b;'
  );
  window.console.log(
    '%c               :yNMMMMMMMMMMMMMMMMMMMm`            ',
    'color: #797979;'
  );
  window.console.log(
    '%c                  :smMMMMMMMMMMMMMMMh`             ',
    'color: #767676;'
  );
  window.console.log(
    '%c                     `:+shmNMMMNmho.               ',
    'color: #737373;'
  );
  window.console.log(
    '%c                                    LukePomfrey.org',
    'color: #666666;'
  );
  window.console.log('');
}

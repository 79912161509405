import Rainbow from "@tabuckner/rainbow-code";

import * as ascii from "./ascii.js";

window.BaseJS = window.BaseJS || {};

if (!window.BaseJS.asciied) {
  ascii.lukepomfrey();
  window.BaseJS.asciied = true;
}

if (!window.BaseJS.rainbowColored) {
  Rainbow.color();
  window.BaseJS.rainbowColored = true;
}

/*
 * PWA install
 */
if (!window.BaseJS.pwaSetup) {
  let deferredPrompt;
  const addBtn = document.querySelector(".appinstall-link");
  addBtn.style.display = "none";

  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI to notify the user they can add to home screen
    addBtn.style.display = "block";

    addBtn.addEventListener("click", () => {
      // hide our user interface that shows our A2HS button
      addBtn.style.display = "none";
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // eslint-disable-next-line no-console
          console.log("User accepted the A2HS prompt");
        } else {
          // eslint-disable-next-line no-console
          console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt = null;
      });
    });
  });
  window.BaseJS.pwaSetup = true;
}

/*
 * form validation
 */
function initFormValidation(elem) {
  try {
    elem.addEventListener("invalid", (ev) => {
      ev.target.setAttribute("aria-invalid", true);
    });

    elem.addEventListener("blur", (ev) => {
      if (ev.target.checkValidity) {
        ev.target.setAttribute("aria-invalid", !ev.target.checkValidity());
      }
    });

    elem.addEventListener("change", (ev) => {
      if (ev.target.checkValidity) {
        ev.target.setAttribute("aria-invalid", !ev.target.checkValidity());
      }
    });
  } catch (e) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

if (!window.BaseJS.formValidation) {
  Array.prototype.forEach.call(
    document.querySelectorAll("form"),
    initFormValidation,
  );

  const formValidationObserver = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      Array.prototype.forEach.call(mutation.addedNodes, (node) => {
        if (node.nodeName.toLowerCase() === "form") {
          initFormValidation(node);
        } else if (node.querySelectorAll) {
          Array.prototype.forEach.call(
            node.querySelectorAll("form"),
            initFormValidation,
          );
        }
      });
    });
  });

  formValidationObserver.observe(document.body, {
    subtree: true,
    childList: true,
  });

  window.BaseJS.formValidation = true;
}
